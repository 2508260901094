main[data-barba-namespace='issues'] {
  .cards {
    --cols: 2;
    @include viewports(('tablet', 'mobile')) {
      --cols: 1;
    }

    // Hide vertical rulers
    &::after { display: none; }
  }
}
