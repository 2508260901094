@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';

$debug: false;

$breakpoints: (
  'desktop': (1080px, 9999px),
  'tablet': (768px, 1080px),
  'mobile': (0px, 768px)
);

@mixin viewport ($key) {
  $breakpoint: map-get($breakpoints, $key);
  $min: nth($breakpoint, 1);
  $max: nth($breakpoint, 2);
  @media (min-width: $min) and (max-width: $max) { @content; }
}

@mixin viewports ($keys) {
  @each $key in $keys {
    @include viewport ($key) { @content; }
  }
}

:root {
  --color-background: #FDFDFD;
  --color-text: #222222;
  --color-dim: #ACABBB;
  --color-border: rgba(0 0 0 / 6%);
  --border: 1px solid var(--color-border);

  --cols: 4;
  --gutter: 0rem;
  --space-safe: 0.5rem;
  --space-inline: 3.0rem;
  --space-block: 3.0rem;
  --article-overflow: -10rem;
  --cards-stagger: 10rem;

  --font-family-menu: 'Fakt Pro', sans-serif;
  --font-weight-menu: bold;
  --font-size-menu: 1.5rem;
  --line-height-menu: 1.2;
  --text-transform-menu: uppercase;
  --text-decoration-thickness-menu: 1px;

  --font-family-label: var(--font-family-menu);
  --font-weight-label: var(--font-weight-menu);
  --font-size-label: 1.2rem;
  --line-height-label: 1.4;
  --text-transform-label: var(--text-transform-menu);

  --font-family-cta: var(--font-family-menu);
  --font-weight-cta: var(--font-weight-menu);
  --font-size-cta: 1.2rem;
  --line-height-cta: var(--line-height-menu);
  --text-transform-cta: var(--text-transform-menu);

  --font-family-figcaption: var(--font-family-menu);
  --font-size-figcaption: 1.4rem;
  --line-height-figcaption: var(--line-height-menu);

  --font-family-heading: 'JeanLuc', sans-serif;
  --font-size-heading: 7.0rem;
  --line-height-heading: 0.85;
  --text-transform-heading: uppercase;

  --font-family-heading-small: var(--font-family-heading);
  --font-size-heading-small: 3.2rem;
  --line-height-heading-small: 0.9;
  --text-transform-heading-small: var(--text-transform-heading);

  --font-family-summary: 'Fakt Pro', sans-serif;
  --font-size-summary: 2.6rem;
  --line-height-summary: 1.1;
  --font-weight-summary: normal;

  --font-family-body: 'Tiempos', serif;
  --font-size-body: 2.0rem;
  --line-height-body: 1.3;
  --text-decoration-thickness-body: 1px;

  --font-family-body-large: var(--font-family-body);
  --font-weight-body-large: var(--font-family-body);
  --font-size-body-large: 2.4rem;
  --line-height-body-large: 1.2;

  --font-family-body-small: var(--font-family-body);
  --font-weight-body-small: var(--font-family-body);
  --font-size-body-small: 1.4rem;
  --line-height-body-small: 1.3;

  @include viewport('tablet') {
    --cols: 3;
    --font-size-menu: 1.2rem;
    --line-height-menu: 1.2;
  }

  @include viewport('mobile') {
    --cols: 1;

    --space-safe: 1rem;
    --space-inline: var(--space-safe);
    --space-block: var(--space-safe);

    --article-overflow: calc(-1 * var(--space-inline));

    --font-size-menu: 3rem;
    --font-size-body-small: 1.6rem;
  }
}

@mixin font ($suffix) {
  font-family: var(--font-family-#{$suffix}, inherit);
  font-size: var(--font-size-#{$suffix}, inherit);
  font-weight: var(--font-weight-#{$suffix}, inherit);
  line-height: var(--line-height-#{$suffix}, inherit);
  text-transform: var(--text-transform-#{$suffix}, none);
  font-variant: var(--font-variant-#{$suffix}, none);
  text-decoration-thickness: var(--text-decoration-thickness-#{$suffix}, 1px);
}

@mixin underline ($color: transparent) {
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: inherit;
  text-decoration-color: $color;

  &:hover {
    text-decoration-color: currentColor;
  }
}

@mixin grid ($cols: var(--cols)) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  gap: var(--gutter);
}

@mixin stagger ($nth: 'even') {
  & > *:nth-child(#{$nth}) {
    margin-top: var(--cards-stagger);
    margin-bottom: calc(-1 * var(--cards-stagger));
  }
}

@import 'sass/base';

@import 'components/Article';
@import 'components/Cards';
@import 'components/cards/Author';
@import 'components/cards/BigText';
@import 'components/cards/Contribution';
@import 'components/cards/Issue';
@import 'components/cards/ShopItem';
@import 'components/Menu';
@import 'components/Photoswipe';
@import 'components/Slideshow';

@import 'templates/default';

@import 'views/authors';
@import 'views/contributions';
@import 'views/home';
@import 'views/issues';
@import 'views/search';
@import 'views/shop';
