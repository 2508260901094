@font-face {
  font-family: 'JeanLuc';
  font-display: auto;
  src: url('/assets/fonts/jeanluc/jeanluc-thin.woff') format('woff'),
       url('/assets/fonts/jeanluc/jeanluc-thin.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('/assets/fonts/tiempos/tiempostext-regular.woff') format('woff'),
       url('/assets/fonts/tiempos/tiempostext-regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('/assets/fonts/tiempos/tiempostext-semibold.woff') format('woff'),
       url('/assets/fonts/tiempos/tiempostext-semibold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Fakt Pro';
  font-display: auto;
  src: url('/assets/fonts/faktpro/faktpro-blond.woff') format('woff'),
       url('/assets/fonts/faktpro/faktpro-blond.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
  ascent-override: normal;
  descent-override: 0%;
}

@font-face {
  font-family: 'Fakt Pro';
  font-display: auto;
  src: url('/assets/fonts/faktpro/faktpro-semibold.woff') format('woff'),
       url('/assets/fonts/faktpro/faktpro-semibold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
  ascent-override: normal;
  descent-override: 0%;
}
