.article {
  position: relative;
  margin-bottom: 10vh;

  figure.cover, .slideshow__slides {
    height: 75vh;
    overflow: hidden;

    @include viewport('mobile') {
      height: unset;

      &.slideshow__slides {
        height: 75vh;
      }
    }
  }

  &__header, &__summary, &__content {
    @include font('body');
    position: relative;
    max-width: Min(50vw, 60rem);
    margin-block: var(--space-block);
    margin-inline: auto;

    @include viewport('mobile') {
      margin-inline: var(--space-inline);
      width: auto;
      max-width: unset;
    }
  }

  &__header .label {
    @include underline;
    position: relative;
    z-index: 1;
  }

  &__header h2 {
    @include font('heading');
  }

  &__header h3 {
    @include font('heading-small');
    margin-top: 0.5em;
  }

  &__summary {
    @include font('summary');
  }

  &__aside {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 0.35em;
    margin-right: var(--space-inline);
    right: 100%;
    height: 100%;

    text-align: right;
    width: calc(25vw - var(--space-inline) * 2);

    @include viewport('mobile') {
      position: relative;
      width: unset;
      right: unset;
      height: unset;
      text-align: left;
      align-items: flex-start;
      margin-block: var(--space-block);
    }

    .label a {
      @include underline;
    }

    .label + .cta {
      margin-top: 0.5em;
    }

    .cta {
      @include font('cta');
      color: var(--color-dim);
    }
  }

  &__text {
    figure {
      margin-block: var(--space-block);
      margin-inline: var(--article-overflow);

      &.is-overlapping-footnote {
        margin-right: 0;
      }

      figcaption {
        @include viewport('mobile') {
          padding-inline: var(--space-inline);
        }
      }
    }

    hr {
      border: 0;
      border-top: var(--border);
      margin-block: var(--space-block);
    }

    .footnotes {
      @include font('body-small');
      margin-top: var(--space-block);
      color: var(--color-dim);
      line-height: 1.4;

      hr { display: none; }

      ol {
        list-style: decimal;
        @include viewport('mobile') {
          list-style: decimal inside;
        }
      }

      a {
        word-break: break-word;
      }

      li {
        margin-block: 0.5em;

        @include viewport('desktop') {
          position: absolute;
          left: 100%;
          margin-left: var(--space-inline);
          top: var(--offset-top);

          width: Min(30ch, calc(25vw - var(--space-inline) * 2));
          margin-top: 5px;

          &::marker {
            color: var(--accent);
          }

          a[rev='footnote'] {
            display: none;
          }
        }
      }

      a[rev='footnote'] {
        text-decoration: none;
      }
    }
  }

  &__footer {
    @include font('body');
    user-select: all;
    margin-block: calc(2 * var(--space-block));

    span {
      word-break: break-all;
    }

    & > .label {
      margin-bottom: 1em;
    }
  }

  &__related {
    @include grid(3);
    position: relative;
    height: min-content;
    margin-inline: -25vw;
    gap: var(--space-inline);
    padding-inline: var(--space-inline);

    @include viewport('mobile') {
      display: block;
      margin-inline: unset;
      padding-inline: unset;
    }

    .card {
      overflow: hidden;

      header, .contribution__content {
        margin-inline: unset;
        margin-block: 1em;
      }
    }
  }
}
