.cards {
  @include grid;
  position: relative;
  height: min-content;

  .card {
    overflow: hidden;

    @include viewport('mobile') {
      padding-bottom: var(--space-block);
    }
  }

  .card.blank {
    @include font('heading-small');
    @include no-select;
    background: var(--accent);
    padding-inline: var(--space-inline);
    padding-block: var(--space-block);
    color: transparent;
  }

  &::after {
    content: '';
    pointer-events: none;
    position: fixed;
    inset: 0;
    background-size: calc((100vw - 1px) / var(--cols));
    background-image: linear-gradient(to right, var(--color-border) 1px, transparent 0);
  }
}
