.slideshow {
  display: flex;
  flex-direction: column;

  &__slides {
    @include no-select;
    background: var(--color-text);
    position: relative;
    height: inherit;

    button {
      @include no-select;
      @include reset-button;
      @include font('menu');
      cursor: pointer;

      color: var(--color-background);
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10rem;

      &::before {
        --optical-y: 1px;
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2em;
        height: 2em;
        padding: 2rem;
        border-radius: 100%;
        margin-top: var(--optical-y);
        background: var(--color-text);
        transform: translate(-50%, -50%);

        @include viewport('mobile') {
          --optical-y: 3px;
        }
      }

      &[data-action='prev'] { left: 0; }
      &[data-action='next'] { right: 0; }

      &:active {
        transform: translateY(1px);
      }
    }
  }

  &__slide {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    position: absolute;
    inset: 0;

    figure {
      height: 100%;
    }

    img {
      object-fit: contain;
    }
  }

  html.js & figcaption {
    display: none;
  }

  &__toolbar {
    display: flex;
    background: var(--color-border);
    padding-inline: var(--space-inline);
    padding-block: var(--space-block);

    justify-content: center;
  }

  &__text {
    width: Min(50vw, 60rem);

    @include viewport('mobile') {
      width: 100%;
    }
  }

  &__counter {
    @include font('label');

    &::after {
      content: '/'attr(data-count);
    }
  }

  &__caption {
    @include font('body-small');
  }

  &:not(.has-started) &__slide:not(:first-child),
  &.has-started &__slide:not(.is-visible) {
    opacity: 0;
    pointer-events: none;
  }
}
