.pswp {
  @include font('menu');
  cursor: zoom-out;

  &__bg {
    background-color: var(--color-background);
  }

  &__img {
    background-color: transparent;
    padding: var(--space-block);
  }

  &__counter {
    color: var(--color-text);
    padding-inline: var(--space-inline);
    padding-block: var(--space-block);
    text-align: left;
    font-variant-numeric: tabular-nums;
  }

  &__button,
  &__button--arrow--left:before,
  &__button--arrow--right:before {
    background-image: url('/assets/photoswipe.svg') !important;
  }

  &__button {
    &::before {
      background-color: transparent;
    }

    &--arrow--left,
    &--arrow--right {
      background-image: none !important;
    }

    &--arrow--left { left: 3rem; }
    &--arrow--right { right: 3rem; }
  }

  &__top-bar {
    background: none !important;
    padding: 3rem;
    padding-right: 3rem;
  }
}
