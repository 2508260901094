.menu {
  @include no-select;
  @include grid;

  z-index: 2;
  position: sticky;
  top: 0;
  background: var(--color-background);

  h1 {
    padding: var(--space-safe);

    @include viewport('mobile') {
      display: flex;
      justify-content: space-between;
    }

    svg {
      fill: currentColor;

      @include viewport('mobile') {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }

    label[for='toggleMenu'] {
      cursor: pointer;

      svg {
        width: 25px;
      }

      @include viewports (('desktop', 'tablet')) {
        display: none;
      }
    }
  }

  &__links {
    @include font('menu');
    @include grid(calc(var(--cols) - 1));

    --rows: 2;

    grid-column: auto / span calc(var(--cols) - 1);
    grid-template-rows: repeat(var(--rows), 1fr);
    grid-auto-flow: column;
    align-items: center;
    padding-block: var(--space-safe);

    @include viewport('tablet') {
      --rows: 3;
    }

    @include viewport('mobile') {
      z-index: -1;
      position: fixed;
      inset: 0;
      background: var(--color-background);
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      gap: var(--space-block);
      justify-content: center;

      li {
        width: 100%;
        padding-inline: var(--space-inline);
        text-align: center;
      }
    }

    a:hover,
    a.is-active {
      @include underline(currentColor);
    }

    a.is-active {
      position: relative;
      padding-left: 1.5ch;

      &::before {
        content: '→\00a0';
        position: absolute;
        left: 0;
        margin-top: -0.05em;
      }
    }
  }

  form {
    position: relative;


    input[type='search'] {
      @include reset-button;
      @include font('menu');

      width: 100%;
      padding-right: calc(2ch + var(--space-safe));
      text-overflow: ellipsis;

      @include viewport('mobile') {
        text-align: center;
        padding-right: unset;
      }

      &::placeholder {
        color: inherit;
      }

      &:focus::placeholder {
        color: var(--color-dim);
      }
    }

    input[type='search']:placeholder-shown + button {
      display: none;
    }

    button {
      @include reset-button;
      position: absolute;
      top: -1px;
      right: var(--space-safe);
      cursor: pointer;

      @include viewport('mobile') {
        display: none;
      }

      svg {
        height: 100%;
        width: auto;
        fill: currentColor;
      }
    }
  }

  #toggleMenu {
    display: none;
  }

  #toggleMenu:checked + h1 label[for='toggleMenu'] {
    transform: rotate(90deg);
  }

  #toggleMenu:not(:checked) ~ &__links {
    @include viewport('mobile') {
      display: none;
    }
  }
}
