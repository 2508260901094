main[data-barba-namespace='contributions'] {
  &[issue] {
    @include grid;

    --cols-left: 2;
    --cols-right: 2;

    @include viewport('tablet') {
      --cols-left: 2;
      --cols-right: 1;
    }

    @include viewport('mobile') {
      --cols-left: 1;
      --cols-right: 1;
    }
  }

  .issue {
    grid-column: auto / span var(--cols-left);
    height: calc(100% + var(--cards-stagger));
  }

  .cards {
    @include viewport('desktop') {
      @include stagger('2n');
    }

    @include viewport('tablet') {
      @include stagger('3n - 1');
      --cols: 3;
    }
  }

  &[issue] .cards {
    @include grid(var(--cols-right));
    grid-column: auto / span var(--cols-right);

    @include viewport('desktop') {
      @include stagger('even');
    }

    .card.blank {
      display: none;
    }

    &::after {
      position: absolute;

      @include viewports(('tablet', 'mobile')) {
        display: none;
      }
    }
  }
}
