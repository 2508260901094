main[data-barba-namespace='home'] {
  @include grid;

  --cols-left: 2;
  --cols-right: 2;

  @include viewport('tablet') {
    --cols-left: 2;
    --cols-right: 1;
  }

  @include viewport('mobile') {
    --cols-left: 1;
    --cols-right: 1;
  }

  .edito {
    grid-column: auto / span var(--cols-left);
    height: calc(100% + var(--cards-stagger));

    // Quick and easy way to stretch .issue full height
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .cards.contributions {
    @include grid(var(--cols-right));
    grid-column: auto / span var(--cols-right);

    @include viewport('desktop') {
      @include stagger('odd');
    }

    &::after {
      position: absolute;

      @include viewports(('tablet', 'mobile')) {
        display: none;
      }
    }
  }
}
