.shop-item {
  @include font('body-small');

  header, &__content, form {
    margin-inline: var(--space-inline);
    margin-block: var(--space-block);
  }

  &:not(:hover) figure {
    filter: grayscale(100%);
  }

  h2 {
    @include font('heading-small');
  }

  form {
    margin-left: auto;

    button {
      @include font('label');
    }
  }
}
