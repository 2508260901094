.issue {
  @include font('body');
  background: var(--accent);

  header, &__content, footer {
    margin-inline: var(--space-inline);
  }

  header, footer {
    margin-block: var(--space-block);
  }

  header {
    margin-top: var(--space-block);

    h2 {
      @include font('heading');
    }
  }

  .label, .cta, .paypal {
    color: currentColor;
  }

  .cta {
    font-size: 1.6rem;
  }
}
