.author {
  @include font('body-small');

  header, &__content {
    margin-inline: var(--space-inline);
    margin-block: var(--space-block);
  }

  &:hover a h2 {
    color: var(--accent);
  }

  &:not(:hover) figure {
    filter: grayscale(100%);
  }

  h2 {
    @include font('heading-small');
  }
}
