html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-padding-top: 6rem;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font('body');

  min-height: 100%;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: var(--color-background);
  color: var(--color-text);
}

::selection  {
  background: var(--color-text);
  color: var(--accent, var(--color-background));
  text-shadow: none;
}

[data-photoswipe] {
  cursor: pointer;
  cursor: zoom-in;
}

img, svg {
  @include no-select;

  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  width: 100%;
  height: auto;
}

img {
  width: 100%;
  height: auto;
}

figure {
  img {
    object-position: var(--focus-x, 50%) var(--focus-y, 50%);
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  figcaption {
    @include font('figcaption');
    color: var(--color-dim);
    margin-top: 0.5em;
  }
}

a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: inherit;

  .prose & {
    @include underline(currentColor);
  }
}

[data-contrast='#ffffff'] { color: var(--color-background); }
[data-contrast='#000000'] { color: var(--color-text); }

.label {
  @include no-select;
  @include font('label');
  color: var(--accent, currentColor);
}

.cta {
  @include no-select;
  @include font('cta');
  @include underline;

  position: relative;
  display: block;
  line-height: inherit;
  padding-left: 1.5ch;

  &::before {
    content: '→\00a0';
    position: absolute;
    left: 0;
    margin-top: -0.05em;
  }

  &[disabled] {
    pointer-events: none;
    &::before { display: none; }
  }

  &[href^='#']::before {
    content: '↓\00a0';
  }
}

form.paypal {
  @include font('cta');
  color: var(--accent);
  width: fit-content;

  button {
    @include underline;
    color: inherit;
    cursor: pointer;
    border: 0;
    appearance: none;
    background: none;
  }
}

.prose {
  h1, h2, h3, h4, h5, h6 {
    @include font('heading');
    margin-block: 1em;
  }

  h1 { font-size: calc(0.5 * var(--font-size-heading)); }
  h2 { font-size: calc(0.4 * var(--font-size-heading)); }
  h3 { font-size: calc(0.3 * var(--font-size-heading)); }
  h4 { font-size: calc(0.3 * var(--font-size-heading)); }
  h5 { font-size: calc(0.3 * var(--font-size-heading)); }
  h6 { font-size: calc(0.3 * var(--font-size-heading)); }

  sup {
    vertical-align: super;
    color: var(--accent);
    line-height: 0;
    a {
      font-size: small;
      text-decoration: none;
    }
  }

  blockquote {
    @include font('body-large');
    margin-block: 2em;
    text-align: center;
    color: var(--color-dim);

    @include viewport('mobile') {
      padding-inline: var(--space-inline);
    }
  }

  p + p {
    margin-top: 1em;
  }
}
