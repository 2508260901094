main[data-barba-namespace='authors'] {
  .cards {
    @include viewport('desktop') {
      @include stagger('2n');
    }

    @include viewport('tablet') {
      @include stagger('3n - 1');
      --cols: 3;
    }
  }
}
