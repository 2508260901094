main[data-barba-namespace='search'] {
  & > header {
    @include font('heading-small');
    margin-left: 25vw;
    width: 50vw;
    margin-block: var(--space-block);

    @include viewport('mobile') {
      margin-inline: var(--space-inline);
      width: auto;
    }

    span {
      color: var(--accent);

      &::before, &::after {
        color: var(--color-text);
      }

      &::before { content: '«\00A0'; }
      &::after { content: '\00A0»'; }
    }
  }

  .cards {
    @include viewport('desktop') {
      @include stagger('2n');
    }

    @include viewport('tablet') {
      @include stagger('3n - 1');
      --cols: 3;
    }
  }
}
